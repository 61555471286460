<template>
  <div class="centivo plan-affiliations-form-wrapper">
    <div class="plan-affiliations-form">
      <div>
        <div class="plan-affiliation-content">
          <span class="steps">1</span>
          <p>Select the plan type</p>
        </div>

        <div class="plans-switch">
          <b-field>
            <b-radio
              size="is-large"
              native-value="Javelina"
              v-model="planType"
              @input="planTypeInputHandler"
            >
              Javelina
            </b-radio>
          </b-field>
          <b-field>
            <b-radio
              size="is-large"
              native-value="HRP"
              v-model="planType"
              @input="planTypeInputHandler"
            >
              HRP
            </b-radio>
          </b-field>
        </div>
      </div>

      <div v-if="planType">
        <div class="plan-affiliation-content">
          <span class="steps">2</span>
          <p>
            Select the client ID to affiliate the configurations to the plan
          </p>
        </div>
        <form>
          <DropDownComponent
            class="client-id-dropdown"
            :name="clientId"
            :planType="planType"
            label="Client ID"
            :options="clientOptions"
            v-model="selectedClient"
            @update:selectedOption="clientIdChangeHandler"
          />
        </form>
      </div>

      <div
        v-if="
          selectedClient &&
          selectedClient.title !== 'NONE' &&
          clientId != 'NONE'
        "
      >
        <div class="plan-affiliation-content">
          <span class="steps">3</span>
          <p>Select a network name to affiliate to each plan ID</p>
        </div>

        <div class="table-wrapper">
          <div class="table-header">
            <p>PLAN ID</p>
            <p>NETWORK NAME</p>
          </div>
          <div class="table-content" v-if="selectedClient">
            <div
              v-for="item in selectedClient.plans ?? []"
              :key="item.planId"
              class="plans-list"
            >
              <div class="plan-id" v-if="item.planId">{{ item.planId }}</div>
              <form>
                <DropDownComponent
                  :name="item.planId"
                  :options="item.networkAffiliationOptions"
                  @update:selectedOption="planNetworkAffiliationChangeHandler"
                  v-if="item.planId"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <json-viewer
        theme="my-awesome-json-theme"
        :value="{
          isEqual,
          planType,
          networkOptions,
          clientOptions,
          initiallySelectedClient,
          selectedClient,
          plansWithNetworkAffilication, //existing
          jvPlans,
          hrpPlans,
          jvPlansGroupedByClientId,
          hrpPlansGroupedByClientId,
        }"
        :expand-depth="1"
      />

      <vue-json-compare
        :oldData="initiallySelectedClient"
        :newData="selectedClient"
      />
    </div> -->
  </div>
</template>

<script>
import DropDownComponent from './../form/DropDownComponent.vue'

import store from '@/store/store'
// import JsonViewer from 'vue-json-viewer'
// import VueJsonCompare from 'vue-json-compare'
import _ from 'lodash'
// import { mapGetters } from 'vuex'
// import { toRaw } from 'vue'

export default {
  components: {
    // JsonViewer,
    // VueJsonCompare,
    DropDownComponent

  },
  props: {
    networkNames: {
      type: Array
    }
  },
  data () {
    return {
      jvPlans: [],
      hrpPlans: [],

      jvPlansGroupedByClientId: [],
      hrpPlansGroupedByClientId: [],

      initiallySelectedPlanType: '',
      planType: '',

      initiallySelectedClient: null,
      clientId: '',
      selectedClient: {
        title: 'NONE',
        value: ''
      },
      refreshClientOptions: 0
    }
  },
  computed: {
    networkOptions () {
      console.log('networkOptions')
      return this.networkNames
    },
    plansWithNetworkAffilication () {
      console.log('plansWithNetworkAffilication')
      return [...this.jvPlans, ...this.hrpPlans].filter((p, i) => {
        if (!p.networkAffiliation) return false
        return this.networkOptions
          .map((netOpt) => netOpt.value)
          .includes(p.networkAffiliation.uuid)
      })
    },
    clientOptions () {
      console.log('clientOptions')
      const options =
        this.planType === 'HRP'
          ? this.getClientOptions(this.hrpPlansGroupedByClientId)
          : this.getClientOptions(this.jvPlansGroupedByClientId)
      return options
    },
    isEqual () {
      return _.isEqual(
        { ...this.initiallySelectedClient, plans: _.map(this.initiallySelectedClient?.plans, obj => _.omit(obj, ['networkAffiliationOptions'])) },
        { ...this.selectedClient, plans: _.map(this.selectedClient?.plans, obj => _.omit(obj, ['networkAffiliationOptions'])) }
      )
    }
  },
  methods: {
    getClientOptions (plansGroupedByClientId) {
      const options = Object.keys(plansGroupedByClientId)
        .map((clientId) => ({
          title: clientId,
          value: clientId,
          plans: plansGroupedByClientId[clientId]
            .map((p) => {
              const networkAffiliationOptions = this.networkOptions.map(
                (netOpt) => {
                  if (
                    p.networkAffiliation &&
                    p.networkAffiliation.uuid === netOpt.value
                  ) {
                    netOpt.isSelected = true
                  } else {
                    netOpt.isSelected = false
                  }
                  return netOpt
                }
              )

              p.networkAffiliationOptions = JSON.parse(
                JSON.stringify(networkAffiliationOptions)
              )
              return p
            })
            .sort((a, b) => a.planId.localeCompare(b.planId)),
          disabled: plansGroupedByClientId[clientId].some(
            (plan) =>
              plan.networkAffiliation &&
              plan.networkAffiliation.clientName !==
                this.$route.params.clientName
          ),
          isSelected: this.clientId === clientId
        }))
        .sort((a, b) => a.title.localeCompare(b.title))

      options.unshift({
        title: 'NONE',
        plans: null,
        disabled: false,
        isSelected: false
      })

      return options
    },
    planTypeInputHandler (planType) {
      console.log('planTypeInputHandler', { planType })
      if (this.initiallySelectedPlanType === planType) {
        //   this.selectedClient = { ...this.initiallySelectedClient }
        this.clientId = { ...this.initiallySelectedClient }.title
      } else {
        this.selectedClient = {
          title: 'NONE',
          value: ''
        }
        this.clientId = 'NONE'
      }
    },
    clientIdChangeHandler (e) {
      const clientId = e.target.value
      console.log('clientIdChangeHandler', { clientId })

      this.clientId = clientId
      //   console.log({ clientId })
      //   console.log(this.clientOptions.find(c => c.value === clientId))
      this.selectedClient = {
        title: clientId,
        value: clientId,
        plans: this.clientOptions
          ?.find((c) => c.title === clientId)
          ?.plans?.sort((a, b) => a.planId.localeCompare(b.planId))
      } // this.allPlansGroupedByClientId[e.target.value]
    },
    planNetworkAffiliationChangeHandler (e) {
      const planId = e.target.name
      const networkUuid = e.target.value
      console.log('planNetworkAffiliationChangeHandler', {
        planId,
        networkUuid
      })
      this.selectedClient.plans = this.selectedClient.plans.map((p) => {
        if (p.planId === planId) {
          p.networkAffiliationOptions = p.networkAffiliationOptions.map(
            (nao) => {
              if (networkUuid === nao.value) {
                nao.isSelected = true
                p.networkAffiliation = {
                  networkName: nao.title,
                  uuid: nao.value,
                  clientName: this.$route.params.clientName
                }
              }

              return nao
            }
          )
        }
        return p
      })
    },
    objectDiff (a, b) {
      return _.fromPairs(
        _.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual)
      )
    }
  },
  created () {
    console.log('created')
    this.jvPlans = store.state.plans.jvPlans
    this.hrpPlans = store.state.plans.hrpPlans

    this.jvPlansGroupedByClientId = [...this.jvPlans].reduce(
      (result, item) => ({
        ...result,
        [item.clientId]: [...(result[item.clientId] || []), item]
      }),
      {}
    )

    this.hrpPlansGroupedByClientId = [...this.hrpPlans].reduce(
      (result, item) => ({
        ...result,
        [item.clientId]: [...(result[item.clientId] || []), item]
      }),
      {}
    )
  },
  watch: {
    plansWithNetworkAffilication (newValue) {
      console.log('plansWithNetworkAffilication', { newValue })
      const firstPlanInAffiliations = newValue?.[0]
      if (firstPlanInAffiliations) {
        this.clientId = firstPlanInAffiliations?.clientId
        this.planType = firstPlanInAffiliations?.planId.startsWith('HRP_')
          ? 'HRP'
          : 'Javelina'

        // set initial values to compare the diff
        if (!this.initiallySelectedPlanType) {
          this.initiallySelectedPlanType = this.planType
        }

        // set initial values to compare the diff
        if (!this.initiallySelectedClient) {
          this.initiallySelectedClient = JSON.parse(
            JSON.stringify({
              // isInitiallySelectedClient: true,
              title: firstPlanInAffiliations?.clientId,
              value: firstPlanInAffiliations?.clientId,
              plans: newValue.sort((a, b) => a.planId.localeCompare(b.planId)),
              disabled: false,
              isSelected: true
            })
          )
        }
      }
    },
    clientOptions (newValue) {
      console.log('clientOptions', { newValue })
      this.selectedClient = newValue.find((c) => c.isSelected)
    },
    selectedClient: {
      handler: function (newVal, oldValue) {
        console.log('selectedClient', { newVal })
        if (!this.isEqual && newVal && newVal?.plans?.some(p => p.networkAffiliation)) {
          this.$emit('enableSavePlanAffiliation', { ...newVal, planType: this.planType })
        } else {
          this.$emit('enableSavePlanAffiliation', { title: 'NONE' })
        }
      },
      deep: true
    }
    // isEqual (newValue) {
    //   if (!newValue) {
    //     // TODO validate the form and enable saving

    //     this.$emit('enableSavePlanAffiliation', this.selectedClient)
    //   } else {
    //     this.$emit('enableSavePlanAffiliation', null)
    //   }
    // }
  }
}
</script>

<style scoped></style>
