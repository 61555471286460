<template>
  <main>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <alert-modal
      v-if="isSaveNetworkModalOpen"
      title="Are you sure you want to exclude the pre-enrollment site?"
      text="There will be no PE site for this client, as none of the networks are selected to be included."
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      @primaryBtnClick="createOrUpdateClient"
      @secondaryBtnClick="isSaveNetworkModalOpen = false"
      @close="isSaveNetworkModalOpen = false"
    />
    <alert-modal
      v-if="isSaveModalOpen"
      title="Save your work without publishing the pre-enrollment site"
      text="The configurations is completed but you have opted not to publish. You can come back at a later date to publish."
      primaryBtnText="Not yet, just save my work"
      secondaryBtnText="Publish pre-enrollment site"
      dismissible
      @primaryBtnClick="createOrUpdateClient"
      @secondaryBtnClick="submitPreEnrollment"
      @close="isSaveModalOpen = false"
    />
    <alert-modal
      v-if="isSavePlanAffiliationModalOpen"
      title="One or more plans are not associated with a network"
      text="Provider and network data will not be available for these plans"
      primaryBtnText="Save anyway"
      secondaryBtnText="Keep working"
      dismissible
      @primaryBtnClick="onAffiliationModalConfirm"
      @secondaryBtnClick="onKeepWorking"
      @close="isSavePlanAffiliationModalOpen = false"
    />
    <alert-modal
      v-if="getShowNetworkAlertModal"
      title="Unsaved work!"
      text="Your work has not been saved and will be lost."
      secondaryBtnText="Continue working"
      primaryBtnText="Leave this page"
      @primaryBtnClick="goHome"
      @secondaryBtnClick="keepChanges"
    />
        <!--
    <json-viewer
      theme="my-awesome-json-theme"
      :value="{draft, clientData}"
      :expand-depth="2"
    /> -->
    <div class="config--save-container">
      <alert
        v-if="saveError && (preEnrollmentInvalid || networksInvalid)"
        type="error"
        class="save--alert"
      >
        <span v-if="networksInvalid && preEnrollmentInvalid"
          >Please fix all errors, and try saving again.</span
        >
        <span v-if="networksInvalid && !preEnrollmentInvalid">
          Please fix the errors in the
          <span class="error--bold"> Networks </span>
          tab, and try saving again.
        </span>
        <span v-if="preEnrollmentInvalid && !networksInvalid">
          Please fix the errors in the
          <span class="error--bold"> Pre-enrollment </span>
          tab, and try saving again.
        </span>
      </alert>
      <alert v-if="unexpectedSaveError" type="error" class="save--alert">
        An unexpected error has occurred while saving.
      </alert>
      <alert v-if="savedSuccess" type="success" class="save--alert">
        You have successfully submitted your work.
      </alert>

      <b-button class="save" @click="onClickSave" :disabled="!saveEnabled"
        >Save</b-button
      >
    </div>

    <b-tabs class="network--tabs" v-model="activeTab">
      <b-tab-item label="Networks">
        <client-form
          v-model="draft"
          @setSaveEnabled="setSaveEnabled"
          @hideValidationErrors="hideValidationErrors"
          :initialName="initialName"
          :showDeepValidationErrors="showDeepValidationErrors"
          :clientConfigNetworkUuids="clientConfigNetworkUuids"
        />
      </b-tab-item>

      <b-tab-item label="Pre-enrollment" :disabled="!preEnrollmentEnabled">
        <b-tooltip
          slot="header"
          label="This tab is not available based on your network configurations. This tab will be available if at least one network is configured to be included on the OE site."
          :animated="false"
          :triggers="!preEnrollmentEnabled ? undefined : []"
        >
          <span>Pre-enrollment</span>
        </b-tooltip>
        <pre-enrollment-form
          :client-data="clientData"
          v-model="draft.preEnrollment"
          :handleSubmit="submitPreEnrollment"
          @setPreEnrollmentInvalid="setPreEnrollmentInvalid"
        />
      </b-tab-item>
      <b-tab-item
        label="Plan affiliation"
        :disabled="!enabledPlanAffiliationTab"
      >
        <PlanAffiliationsFormNew
          v-if="networkNames"
          :key="reloadPlanAffilication"
          :networkNames="networkNames"
          @enableSavePlanAffiliation="enableSavePlanAffiliation"
        />
        <!-- <plan-affiliations-form
          v-if="draft.planAffiliations && clientData && clients"
          v-model="draft.planAffiliations"
          :networkNames="networkNames"
          @setSaveEnabled="setSaveEnabled"
          :key="reloadPlanAffilication"
          :plans="plansInfo"
          :clientData="clientData"
          :active-tab="activeTab === 2"
          :client-options="clients"
          @updateSelectedPlanType="updateSelectedPlanType"
          @onAffiliationsLoad="onAffiliationsLoad"
          @setClientCurrentPlansList="setClientCurrentPlansList"
        /> -->
      </b-tab-item>
      <b-tab-item
        headerClass="tabs--client-name"
        v-if="this.$route.params.clientName"
        :disabled="true"
      >
        <template slot="header">{{ this.$route.params.clientName }}</template>
      </b-tab-item>
    </b-tabs>

  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ClientForm from '@/components/client-form.vue'
import PreEnrollmentForm from '@/components/pre-enrollment-form/pre-enrollment-form.vue'
import PlanAffiliationsFormNew from '@/components/plan-affiliations-form/plan-affiliations-form-new.vue'

import AlertModal from '@/components/alert-modal.vue'
import Alert from '@/components/alert.vue'
import client from '@/api/networkConfigApi'
import { isSuccessResponse } from '@/helpers/is-success-response'
import { cloneDeep, get, isEqual } from 'lodash'
// import PlanAffiliationsForm from '@/components/plan-affiliations-form/plan-affiliations-form.vue'
import store from '@/store/store'
import { createNewConfigArray } from '@/helpers/add-client-config'
import { obsIsEqual } from '@/helpers'
import planApi from '@/api/planApi'
// import JsonViewer from 'vue-json-viewer'

export default {
  name: 'client-config',
  components: {
    AlertModal,
    ClientForm,
    PreEnrollmentForm,
    //   PlanAffiliationsForm,
    Alert,
    //   JsonViewer,
    PlanAffiliationsFormNew
  },
  data () {
    return {
      activeTab: 0,
      clientData: undefined,
      plans: [],

      plansInfo: [],
      clients: [],
      networkNames: [],
      draft: {
        name: '',
        networkConfigs: createNewConfigArray(),
        evenOptions: [],
        clientNameList: [],
        clientNameInvalid: false,
        loaded: false
      },
      isSaveNetworkModalOpen: false,
      isSaveModalOpen: false,
      isSavePlanAffiliationModalOpen: false,
      saveError: false,
      savedSuccess: false,
      unexpectedSaveError: false,
      saveEnabled: false,
      initialName: '',
      showDeepValidationErrors: false,
      clientConfigNetworkUuids: [],
      networksInvalid: false,
      preEnrollmentInvalid: false,
      preEnrollmentEnabled: false,
      reloadPlanAffilication: 0,
      loading: false,
      enabledPlanAffiliationTab: false,
      clientPlansList: []
    }
  },
  computed: {
    ...mapGetters('networkConfig', [
      'getShowNetworkAlertModal',
      'getNetworkHasChanged'
    ]),
    networkConfigPassedToPlanAffiliation () {
      return this.draft.networkConfigs
    },
    jvPlans () { return store.state.plans.jvPlans },
    hrpPlans () { return store.state.plans.hrpPlans }
    // ...mapGetters('plans', ['getPlans'])
  },
  methods: {
    ...mapActions([
      'networkConfig/setShowNetworkAlertModal',
      'networkConfig/setNetworkHasChanged'
    ]),
    setLoading (value) {
      this.loading = value
    },
    enableSavePlanAffiliation (planAffiliations) {
      console.log('enableSavePlanAffiliation', planAffiliations)
      if (!planAffiliations) {
        this.saveEnabled = false
      } else {
        this.saveEnabled = true
        this.draft.planAffiliations = planAffiliations
      }
    },
    setSaveEnabled (formNoErrors) {
      console.log('setSaveEnabled', { formNoErrors })
      const enablePreEnrollment = this.draft.networkConfigs.find((config) => {
        return config && config.includePreEnrollmentSite
      })
      this.preEnrollmentEnabled = !!enablePreEnrollment

      this.enabledPlanAffiliationTab = !!this.clientData

      const noChanges = obsIsEqual(this.draft, this.clientData ?? {})

      if (
        !this.draft?.name ||
        !this.draft?.networkConfigs[0]?.directoryType ||
        !formNoErrors
      ) {
        this.saveEnabled = false
      } else if (
        this.draft.networkConfigs ||
        this.draft.networkConfigs.length >= 1
      ) {
        this.saveEnabled = !noChanges
      }
    },
    setPlans () {
      console.log('setPlans')
      this.plansInfo = [...this.plans, ...this.hrpPlans]
    },
    setClientCurrentPlansList (plansList) {
      console.log('setClientCurrentPlansList', plansList)
      this.clientPlansList = plansList
    },
    async onClickSave () {
      console.log('onClickSave')
      const isPublished = get(this.clientData, 'preEnrollment.publish')

      if (this.activeTab === 0) {
        const include = this.draft.networkConfigs.some(
          (config) => config.includePreEnrollmentSite
        )

        if (!include) {
          this.savedSuccess = false
          this.saveError = false
          this.unexpectedSaveError = false
          this.isSaveNetworkModalOpen = true
          return
        }
      } else if (this.activeTab === 1) {
        // if the site is already published and the user clicks on save
        if (!isPublished) {
          if (this.draft?.preEnrollment?.customContent) {
            this.createOrUpdateClient()
          } else {
            this.isSaveModalOpen = true
          }
        }
        return
      } else if (this.activeTab === 2) {
        if ((await this.onSavePlanAffiliations()) === false) return
      }

      this.createOrUpdateClient()
    },
    onAffiliationModalConfirm () {
      console.log('onAffiliationModalConfirm')
      this.onSavePlanAffiliations(false)
      this.createOrUpdateClient()
      this.isSavePlanAffiliationModalOpen = false
    },
    async createOrUpdateClient (showAlert = true) {
      console.log('createOrUpdateClient')
      this.isSaveNetworkModalOpen = false
      this.isSaveModalOpen = false
      const data = this.draft
      data.name = data.name.trim()
      this.savedSuccess = false
      this.saveError = false
      this.unexpectedSaveError = false
      this.showDeepValidationErrors = false
      this.errorIndex = 0
      this.networksInvalid = false

      for (const config of data.networkConfigs) {
        if (config.isValid === false) {
          this.showDeepValidationErrors = true
          this.errorIndex = 0
          this.networksInvalid = true
          break
        }
        if (config.name) {
          config.name = config.name.trim()
        }

        if (config && config.directoryType === 'Centivo') {
          delete config.rbpAdministrator
          delete config.thirdPartyNetwork
          delete config.requireVpcModule
        } else if (
          config &&
          config.directoryType === 'Centivo + External/RBP'
        ) {
          delete config.requireVpcModule
        } else if (config && config.directoryType === 'External/RBP') {
          config.networkSeries = []
        }
      }

      if (this.networksInvalid || this.preEnrollmentInvalid) {
        this.saveError = true
        return
      }

      let response

      try {
        if (this.$route.params.clientName) {
          response = await client.updateClientConfig(
            this.clientData?.name,
            data
          )
        } else {
          response = await client.createClientConfig(data)
        }

        if (isSuccessResponse(response)) {
          this['networkConfig/setNetworkHasChanged'](false)
          this.clientData = response.data

          if (this.$route.params.clientName !== data.name) {
            this.$router.replace({
              name: 'ClientConfig',
              params: { clientName: data.name },
              silent: true
            })
          }

          if (showAlert) {
            this.savedSuccess = true
          }
        } else {
          console.error(response.data)
          this.unexpectedSaveError = true
        }
      } catch (e) {
        console.error(e)
        this.unexpectedSaveError = true
      } finally {
        this.setLoading(false)
      }

      this.isSaveModalOpen = false

      return response
    },
    getNetworkNames () {
      console.log('getNetworkNames')
      const networkNames =
        this.draft?.networkConfigs.map((nc) => {
          const ddOption = { title: nc.name, value: nc.uuid }
          return ddOption
        }) ?? []

      this.networkNames = networkNames
      //  this.reloadPlanAffilication = this.reloadPlanAffilication++

      return networkNames
    },
    async getHealthEdgePlans () {
      console.log('getHealthEdgePlans')
      // await store
      //   .dispatch('plans/getAllHealthEdgePlans')
      //   .then((res) => {
      //     this.hrpPlans = res.data
      //   })
      //   .catch((err) => console.error(err))

      // this.setPlans()
    },
    getClients (plans = [], networkNames) {
      console.log('clientconfig getClients')
      const groupByClientId = plans.reduce(
        (result, item) => ({
          ...result,
          [item.clientId]: [...(result[item.clientId] || []), item]
        }),
        {}
      )

      const clients = Object.keys(groupByClientId).map((k) => {
        const disabled = groupByClientId[k].some(
          (plan) =>
            plan.networkAffiliation &&
            plan.networkAffiliation.clientName !== this.$route.params.clientName
        )
        const isSelected = groupByClientId[k].some(
          (plan) =>
            plan.networkAffiliation &&
            networkNames &&
            networkNames
              .map((nn) => nn.value)
              .includes(plan.networkAffiliation.uuid)
        )

        const oneOfTheSelectedPlan = groupByClientId[k].find(
          (plan) =>
            plan.networkAffiliation &&
            networkNames &&
            networkNames
              .map((nn) => nn.value)
              .includes(plan.networkAffiliation.uuid)
        )

        const planType = oneOfTheSelectedPlan?.planId.includes('HRP_')
          ? 'HRP'
          : 'Javelina'

        return {
          title: k,
          plans: groupByClientId[k],
          planType,
          disabled,
          isSelected
        }
      })

      clients.sort((a, b) => a.title.localeCompare(b.title))

      // Allow unaffiliated plans (NONE)
      clients.unshift({
        title: 'NONE',
        plans: null,
        disabled: false,
        isSelected: false
      })

      return clients
    },
    onAffiliationsLoad (v) {
      console.log('onAffiliationsLoad', v)
      if (this.clientData) {
        this.clientData.planAffiliations = v
      }
    },
    async onSavePlanAffiliations (confirmAction = true) {
      console.log('onSavePlanAffiliations')
      if (
        confirmAction &&
        this.clientPlansList?.some((p) => !p.networkAffiliation)
      ) {
        this.isSavePlanAffiliationModalOpen = true

        return false
      }
      this.setLoading(true)
      const changeAffiliationRequestBody = {
        clientId: this.draft?.planAffiliations?.title,
        clientName: this.draft?.name,
        planType: this.draft?.planAffiliations?.planType,
        affiliations: [],
        shouldGetHrpPlans: true
      }

      if (this.draft?.planAffiliations?.title !== 'NONE' && this.draft?.planAffiliations?.plans) {
        for (const plan of this.draft?.planAffiliations?.plans?.filter(
          (p) => p.networkAffiliation
        )) {
          changeAffiliationRequestBody.affiliations.push({
            planId: plan.planId,
            uuid: plan.networkAffiliation.uuid,
            networkName: plan.networkAffiliation.networkName
          })
        }
      } else {
        changeAffiliationRequestBody.affiliations = null
      }

      try {
        const res = await planApi.updatePlanNetworkAffiliationForClient(
          changeAffiliationRequestBody
        )
        const data = res.data
        // update store without refetching all plans
        const plansToUpdateNetworkAffilication = [...data.added, ...data.removed]
        if (plansToUpdateNetworkAffilication.length) {
          const jvPlansToUpdateNetworkAffiliation = plansToUpdateNetworkAffilication.filter(p => !p.planId.includes('HRP_'))
          const hrpPlansToUpdateNetworkAffiliation = plansToUpdateNetworkAffilication.filter(p => p.planId.includes('HRP_'))

          if (jvPlansToUpdateNetworkAffiliation.length) {
            const jvPlans = store.state.plans.jvPlans
            const planIdsToUpdate = jvPlansToUpdateNetworkAffiliation.map(p => p.planId)
            await store.dispatch('plans/updateJvPlans', jvPlans.map(p => {
              if (planIdsToUpdate.includes(p.planId)) {
                p.networkAffiliation = jvPlansToUpdateNetworkAffiliation.find(p => p.planId)?.networkAffiliation
              }
              return p
            }))
          }

          if (hrpPlansToUpdateNetworkAffiliation.length) {
            const hrpPlans = store.state.plans.hrpPlans
            const planIdsToUpdate = hrpPlansToUpdateNetworkAffiliation.map(p => p.planId)
            await store.dispatch('plans/updateHrpPlans', hrpPlans.map(p => {
              if (planIdsToUpdate.includes(p.planId)) {
                p.networkAffiliation = hrpPlansToUpdateNetworkAffiliation.find(p => p.planId)?.networkAffiliation
              }
              return p
            }))
          }
        }

        // store.dispatch('plans/updateJvPlans', )
        const networkNames = this.getNetworkNames()
        // store.dispatch('plans/getPlans').then((res) => {
        //   if (res) {
        //     this.plans = res.data
        //   }
        // })

        // await this.getHealthEdgePlans()
        this.setPlanNetworkConfigs(this.plansInfo)
        this.clients = this.getClients(this.plansInfo, networkNames)

        this['networkConfig/setNetworkHasChanged'](false)
        // this.reloadPlanAffilication = this.reloadPlanAffilication++
        this.savedSuccess = true
      } catch (error) {
        console.log(error)
        this.saveError = true
      } finally {
        this.isSavePlanAffiliationModalOpen = false
        // this.setLoading(false)
      }
    },
    onKeepWorking () {
      this['networkConfig/setShowNetworkAlertModal'](false)
      this.isSavePlanAffiliationModalOpen = false
    },
    async submitPreEnrollment (data) {
      if (!isEqual(this.draft, this.clientData)) {
        const response = await this.createOrUpdateClient(false)

        if (!response || !isSuccessResponse(response)) {
          return
        }
      } else {
        this.isSaveModalOpen = false
      }

      const isPublished = get(this.clientData, 'preEnrollment.publish')

      let response
      if (isPublished) {
        response = await client.unpublishOESite(this.clientData.name)
      } else {
        response = await client.publishOESite(this.clientData.name)
      }

      if (isSuccessResponse(response)) {
        this.clientData = response.data
      }

      return response
    },
    goHome () {
      const toPath = this.getShowNetworkAlertModal || '/plans'

      this['networkConfig/setNetworkHasChanged'](false)
      this.$router.push({ path: toPath }).catch(() => {})
    },
    keepChanges () {
      this['networkConfig/setShowNetworkAlertModal'](false)
    },
    setPlanNetworkConfigs (plans) {
      console.log('setPlanNetworkConfigs')
      for (const plan of plans) {
        if (plan.networkAffiliation && plan.networkAffiliation.uuid) {
          this.clientConfigNetworkUuids.push(plan.networkAffiliation.uuid)
        }
      }
    },
    hideValidationErrors () {
      this.showDeepValidationErrors = false
    },
    setPreEnrollmentInvalid (invalid) {
      this.preEnrollmentInvalid = invalid
    }
  },
  async created () {
    console.log('client config created')
    if (!this.jvPlans?.length || !this.hrpPlans?.length) {
      console.log('fetching all plans')
      await store.dispatch('plans/getAllPlans')
      // this.jvPlans = jvRes.data
      // this.hrpPlans = hrpRes.data

      // this.reloadPlanAffilication = this.reloadPlanAffilication++
      // jvPlans = store.state.plans.jvPlans
      // hrpPlans = store.state.plans.hrpPlans
    }

    this.setLoading(true)
    const networkNames = this.getNetworkNames()

    // await store.dispatch('plans/getPlans').then((res) => {
    //   if (res) {
    //     this.plans = res.data
    //   }
    // })

    this.setPlanNetworkConfigs(this.plansInfo)
    this.clients = this.getClients(this.plansInfo, networkNames)

    if (this.$route.params.clientName) {
      await store.dispatch('networkConfig/getClientConfigs')

      this.isEdit = true
      const response = await client.getClient(this.$route.params.clientName)

      if (isSuccessResponse(response)) {
        const data = {
          evenOptions: [],
          ...response.data
        }

        if (!response.data.planAffiliations) {
          const selectedClient = this.clients.find((c) => c.isSelected)

          const isHrpPlanType = selectedClient?.plans.some((plan) =>
            plan?.planId?.startsWith('HRP_')
          )
          this.clientPlansList = selectedClient?.plans
          data.planAffiliations = {
            // TOOD make planAffiliations if not in data
            planType: isHrpPlanType
              ? 'HRP'
              : selectedClient?.plans
                ? 'Javelina'
                : ''
          }
        }

        if (!data.networkConfigs || data.networkConfigs.length < 1) {
          data.networkConfigs = createNewConfigArray()
        } else {
          for (const config of data.networkConfigs) {
            if (
              config &&
              config.directoryType === 'External/RBP' &&
              config.thirdPartyNetwork !== 'None' &&
              !config.requireVpcModule
            ) {
              config.requireVpcModule = false
            }
          }
        }
        this.clientData = data
        this.draft = cloneDeep(data)
        this.initialName = data.name ? data.name.toLowerCase() : ''
      }
    }
    this.setLoading(false)
  },
  watch: {
    clientData: {
      handler: function (newVal) {
        console.log('clientData changed', { newVal })
        this.draft = cloneDeep(newVal)
        const networkNames = this.getNetworkNames()

        if (networkNames.length > 0 && this.plansInfo.length > 0) {
          this.clients = this.getClients(this.plansInfo, networkNames)
        }

        this['networkConfig/setNetworkHasChanged'](false)
      },
      deep: true
    },
    draft: {
      handler: function (newVal) {
        this['networkConfig/setNetworkHasChanged'](
          !isEqual(newVal, this.clientData)
        )
      },
      deep: true
    },
    activeTab: function () {
      this.saveError = false
      this.unexpectedSaveError = false
      this.savedSuccess = false
    }
  },
  beforeDestroy () {
    this['networkConfig/setShowNetworkAlertModal'](false)
    this['networkConfig/setNetworkHasChanged'](false)
  }
}
</script>

<style lang="scss">
main.network--content.container {
  padding-top: 0;
}

.tabs--client-name {
  opacity: 1 !important;

  a {
    line-height: 1rem;
    border: none;
    padding-left: 2rem;
    font-weight: 600;
    font-size: 1.25rem;
    color: #444;
  }
}

.network--tabs {
  .tabs ul {
    padding-top: 5.9rem;
  }

  .tabs li:not(.tabs--client-name) {
    border: 1px solid #00a69e;
    border-bottom: none;
    width: 9.25rem;

    a,
    a:hover {
      font-weight: 700;
      color: #00a69e;
      border-bottom: none;
    }

    &:not(:last-child) {
      margin-right: 0.125rem;
    }

    &.is-active {
      background-color: #00a69e;

      a {
        color: #fff;
      }
    }

    &.is-disabled {
      border: none;
      background-color: #ebebeb;
      cursor: not-allowed;
      opacity: 1 !important;

      a {
        pointer-events: all;
        color: #bbbdbf;
      }
      a:active {
        pointer-events: none;
      }
    }

    .tooltip-content {
      width: 20rem;
      white-space: pre-wrap;
      margin-bottom: 0.5rem;
      font-weight: 600;
      text-align: left;
      border-radius: 2px;
      padding: 0.5rem;
    }
  }

  .networks-tooltip {
    .tooltip-content {
      margin-left: 85px;
    }
  }
}

.config--save-container {
  position: absolute;
  right: 0;
  top: 6rem;
  transform: translateY(-50%);
  display: flex;

  > button {
    background-color: #ec008c;
    color: #fff;
    font-weight: 700;
    padding: 1rem 2.5rem;
    width: 7rem;
    height: 3rem;

    &:disabled,
    &:hover,
    &:active,
    &:focus {
      border-color: #ec008c;
      color: #ec008c;
      background-color: #ec008c;
      color: #fff;
    }
  }

  .submit--alert {
    margin-top: 2.5rem;
  }

  .save--alert {
    margin-right: 2.5rem;

    .error--bold {
      font-weight: 700;
    }
  }
}
</style>
