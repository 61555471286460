var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"centivo plan-affiliations-form-wrapper"},[_c('div',{staticClass:"plan-affiliations-form"},[_c('div',[_vm._m(0),_c('div',{staticClass:"plans-switch"},[_c('b-field',[_c('b-radio',{attrs:{"size":"is-large","native-value":"Javelina"},on:{"input":_vm.planTypeInputHandler},model:{value:(_vm.planType),callback:function ($$v) {_vm.planType=$$v},expression:"planType"}},[_vm._v(" Javelina ")])],1),_c('b-field',[_c('b-radio',{attrs:{"size":"is-large","native-value":"HRP"},on:{"input":_vm.planTypeInputHandler},model:{value:(_vm.planType),callback:function ($$v) {_vm.planType=$$v},expression:"planType"}},[_vm._v(" HRP ")])],1)],1)]),(_vm.planType)?_c('div',[_vm._m(1),_c('form',[_c('DropDownComponent',{staticClass:"client-id-dropdown",attrs:{"name":_vm.clientId,"planType":_vm.planType,"label":"Client ID","options":_vm.clientOptions},on:{"update:selectedOption":_vm.clientIdChangeHandler},model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1)]):_vm._e(),(
        _vm.selectedClient &&
        _vm.selectedClient.title !== 'NONE' &&
        _vm.clientId != 'NONE'
      )?_c('div',[_vm._m(2),_c('div',{staticClass:"table-wrapper"},[_vm._m(3),(_vm.selectedClient)?_c('div',{staticClass:"table-content"},_vm._l((_vm.selectedClient.plans ?? []),function(item){return _c('div',{key:item.planId,staticClass:"plans-list"},[(item.planId)?_c('div',{staticClass:"plan-id"},[_vm._v(_vm._s(item.planId))]):_vm._e(),_c('form',[(item.planId)?_c('DropDownComponent',{attrs:{"name":item.planId,"options":item.networkAffiliationOptions},on:{"update:selectedOption":_vm.planNetworkAffiliationChangeHandler}}):_vm._e()],1)])}),0):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan-affiliation-content"},[_c('span',{staticClass:"steps"},[_vm._v("1")]),_c('p',[_vm._v("Select the plan type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan-affiliation-content"},[_c('span',{staticClass:"steps"},[_vm._v("2")]),_c('p',[_vm._v(" Select the client ID to affiliate the configurations to the plan ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan-affiliation-content"},[_c('span',{staticClass:"steps"},[_vm._v("3")]),_c('p',[_vm._v("Select a network name to affiliate to each plan ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('p',[_vm._v("PLAN ID")]),_c('p',[_vm._v("NETWORK NAME")])])
}]

export { render, staticRenderFns }